import {type ReactNode, useMemo} from 'react';
import {useTrackedJob} from '~/app/(unauthenticated)/job-board/_/hooks/use-tracked-job';
import {Anchor, Avatar, Card, IconButton, Skeleton} from '@job-ish/ui/components';
import {IconBookmarkEdit, IconBookmarkPlus, IconExternalLink} from '@tabler/icons-react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import type {JobRead} from '@job-ish/job-data-api/client/job-data-api';
import {JobBoardListingActions} from './actions';

dayjs.extend(relativeTime);

export type JobBoardListingHeaderProps = {
	job: JobRead;
	suffix?: ReactNode;
	bookmarkIconOnly?: boolean;
	showBackButton?: boolean;
};

export const JobBoardListingHeader = ({
	job,
	suffix,
	bookmarkIconOnly,
	showBackButton,
}: JobBoardListingHeaderProps) => {
	const {trackedJob, handleTrackJob} = useTrackedJob({job});

	const datePublished = useMemo(() => {
		const localDatePublished = dayjs.utc(job.published).local();
		return localDatePublished.isAfter(dayjs()) ? 'Just now' : localDatePublished.fromNow();
	}, [job.published]);

	return (
		<Card.Header className="w-full flex-col">
			{(!bookmarkIconOnly || suffix || showBackButton) && (
				<JobBoardListingActions
					bookmarkIconOnly={bookmarkIconOnly}
					handleTrackJob={handleTrackJob}
					job={job}
					showBackButton={showBackButton}
					suffix={suffix}
					trackedJob={trackedJob}
				/>
			)}
			<div className="flex w-full items-start justify-start gap-2">
				<Avatar className="shrink-0 rounded-sm border border-mauve7" size="xl">
					{job.company.logo && (
						<Avatar.Image
							alt={`${job.company.name} logo`}
							className="rounded-sm"
							referrerPolicy="no-referrer"
							src={job.company.logo}
						/>
					)}
					<Avatar.Fallback>
						<Skeleton className="h-full w-full rounded-none" />
					</Avatar.Fallback>
				</Avatar>
				<div className="flex flex-col items-start overflow-hidden text-left">
					<Card.Title
						asChild
						className="block w-full items-end gap-1.5 truncate text-sm leading-snug"
						title={job.title}
					>
						<h2 className="truncate">{job.title}</h2>
					</Card.Title>
					<Card.Description asChild>
						<div className="w-full">
							{job.company.website_url ? (
								<Anchor
									className="w-fit max-w-full overflow-hidden text-sm font-medium leading-relaxed text-mauve11 visited:text-mauve11"
									href={job.company.website_url}
									rel="noopener noreferrer"
									suffix={<IconExternalLink className="h-3 w-3 shrink-0" />}
									target="_blank"
								>
									<div className="truncate text-sm font-medium">{job.company.name}</div>
								</Anchor>
							) : (
								<span>{job.company.name}</span>
							)}
						</div>
					</Card.Description>
					{job.published && (
						<span className="w-fit text-xs leading-tight text-mauve11" suppressHydrationWarning>
							{datePublished}
						</span>
					)}
				</div>
				{bookmarkIconOnly && (
					<IconButton
						as="div"
						className="ml-auto"
						icon={trackedJob ? IconBookmarkEdit : IconBookmarkPlus}
						intent="ghost"
						onPress={() => handleTrackJob()}
						size="sm"
					/>
				)}
			</div>
		</Card.Header>
	);
};
